.athContainer {
  background-color: #fff;
  color: #f44336;
  display: none;
  font-family: "Montserrat";

  padding: 2%;
  text-align: center;
}

.athBanner .athCancelButton {
  display: none;
}
.athContentCell {
  align-items: center;
}
.athLogoCell {
  text-align: center;
  grid-area: logo;
}
.athTitleCell {
  align-self: center;
  grid-area: content;
}
.athInstallButtonCell {
  text-align: center;
  grid-area: install;
}
.athInstallButtonCell .button {
  display: unset;
}
.athGuidance {
  display: none;
  grid-template-areas: "cancel" "image";
  position: relative;
}
.athGuidance .athCancelButtonCell {
  display: none;
}
.athGuidanceImageCell {
  grid-area: image;
  text-align: center;
}
.athGuidanceCancelButton {
  display: none;
}
.athLogo {
  display: none;
}
.athTitle {
  font-weight: bold;
  font-size: large;
}
.d-grid {
  display: grid !important;
}
.button {
  text-decoration: none;
  border: none;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  height: 30px;
  padding: 0 20px;
  outline: none;
  align-items: center;
  background: #00f;
  color: #fff;
}
