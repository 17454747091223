@import url(https://fonts.googleapis.com/css2?family=Londrina+Solid&family=Montserrat:wght@100&family=Open+Sans:wght@300&family=Yusei+Magic&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;1,100;1,200;1,300;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.athContainer {
  background-color: #fff;
  color: #f44336;
  display: none;
  font-family: "Montserrat";

  padding: 2%;
  text-align: center;
}

.athBanner .athCancelButton {
  display: none;
}
.athContentCell {
  align-items: center;
}
.athLogoCell {
  text-align: center;
  grid-area: logo;
}
.athTitleCell {
  align-self: center;
  grid-area: content;
}
.athInstallButtonCell {
  text-align: center;
  grid-area: install;
}
.athInstallButtonCell .button {
  display: unset;
}
.athGuidance {
  display: none;
  grid-template-areas: "cancel" "image";
  position: relative;
}
.athGuidance .athCancelButtonCell {
  display: none;
}
.athGuidanceImageCell {
  grid-area: image;
  text-align: center;
}
.athGuidanceCancelButton {
  display: none;
}
.athLogo {
  display: none;
}
.athTitle {
  font-weight: bold;
  font-size: large;
}
.d-grid {
  display: grid !important;
}
.button {
  text-decoration: none;
  border: none;
  display: flex;
  cursor: pointer;
  font-size: 14px;
  line-height: 16px;
  text-transform: uppercase;
  height: 30px;
  padding: 0 20px;
  outline: none;
  align-items: center;
  background: #00f;
  color: #fff;
}

